(function ($) {
  "use strict";

  $(function () {
    $(".categories .toggle").on("click", function () {
      $(this).toggleClass("show");
      $(".form_filters").toggleClass("show");
    });

    $(".-radio").on("click", function () {
      let cat_slug = $(this).find("input").data("cat");
      $(".categories .toggle").removeClass("show");
      $(".form_filters").removeClass("show");
      $(".active_category").text(cat_slug);

      let url = getPathFromUrl(window.location.href);
      url += "?c=" + cat_slug + "#a";
      window.location.href = url;
    });
  });
})(jQuery);

function getPathFromUrl(url) {
  return url.split("?")[0];
}

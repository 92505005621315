(function ($) {
  "use strict";

  $(function () {
    $("svg .-point").bind("mouseover touchstart", function () {
      let obj = $(this).attr("id");
      $("#popup_" + obj).css("display", "block");
    });

    $("svg .-point").bind("mouseout touchend", function () {
      let obj = $(this).attr("id");
      $("#popup_" + obj).css("display", "none");
    });
  });
})(jQuery);

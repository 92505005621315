(function ($) {
  "use strict";

  $(function () {
    //subscribe form footer
    $("#subscribe_submit").click(function () {
      $("#formSubscribe").submit();
    });

    //FOOTER MENU
    // Create a condition that targets viewports at max 768px wide
    const smallDevice = window.matchMedia("(max-width: 769px)");
    let opener = $("#footer h2");
    let menu = $(".footer-menu div");

    function handleDeviceChange(e) {
      if (e.matches) {
        opener.addClass("toggle");
        menu.addClass("closed");
        let menu_btn = $(".toggle");

        menu_btn.click(function () {
          $(this).toggleClass("open");
          $(this).next("div").toggleClass("closed");
        });
      } else {
        opener.removeClass("toggle");
        menu.removeClass("closed");
      }
    }

    // Register event listener
    smallDevice.addListener(handleDeviceChange);

    // Initial check
    handleDeviceChange(smallDevice);
  });
})(jQuery);

document.addEventListener("DOMContentLoaded", function () {
    const from = document.getElementById('anchor');
    const to = document.getElementById('goto');
  
    if (from && to) {
      from.addEventListener('click', function (event) {
        console.log('clicked');
        to.scrollIntoView({ behavior: "smooth" });
      });
    } /* else {
      console.error("Error: 'anchor' or 'goto' element not found on the page.");
    } */
  });
//THEME
import defaults from "./custom/defaults.js";
import navbar from "./custom/navbar.js";
import footer from "./custom/footer.js";

//CUSTOM
import venn from "./custom/venn-diagram.js";
import body from "./custom/body-diagram.js";
import archive from "./custom/archive.js";
import facet from "./custom/facet.js";

//PROJECT
import project from "./custom/project.js";
//EXTRAS
//import cards from './custom/cards.js';
//import progressBar from './custom/progressBar.js';
//import annimate from './custom/annimate.js';

//VueJS
//import footer from './custom/vue-scripts.js';

(function ($) {
  "use strict";

  $(function() {
    // Cache frequently used elements
    const vennBase = $(".-base");
    const vennBaseLabels = $(".-base-labels");
    const vennContainer = $('.venn-diagram .venn');
  
    // Function to toggle the visibility of a Venn section
    function toggleVennSection(sectionSelector) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      $(sectionSelector).toggleClass("hide");
      vennContainer.toggleClass('expanded', !$(sectionSelector).hasClass('hide')); 
    }
  
    // Event listeners for each section
    $("#mindfulness, .-mindfulness").on("click", () => toggleVennSection(".-mindfulness"));
    $("#sound, .-sound").on("click", () => toggleVennSection(".-sound"));
    $("#touch, .-touch").on("click", () => toggleVennSection(".-touch"));
  });
})(jQuery);
